import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Page from "../components/page";
import BlogPost from "../components/blog-post";

export default function BlogPosts(props) {
  const posts = props.data.allContentfulBlogPost.edges.map(x => x.node).sort((a, b) => a.createDate > b.createDate ? -1 : a.createDate > b.createDate ? 1 : 0);
  return (
    <Layout title="FeedMe - Blog" {...props.data.page}>
      <Page>
        {posts.map(p => <BlogPost asLink={true} {...p}></BlogPost>)}
      </Page>
    </Layout>
  );
}

export const query = graphql`
  query {
    allContentfulBlogPost {
      edges {
        node {
          id
          author
          createDate
          slug
          tags
          title
          text {
            childMarkdownRemark {
              html
            }
          }
          mainPhoto {
            gatsbyImageData(layout: CONSTRAINED)
            title
          }
        }
      }
    }
  }  
`;
